var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"newdata"}},[_c('el-row',[_c('el-col',[_c('div',{staticClass:"grid-content bg-purple-dark"}),_c('div',{staticClass:"center"},[_c('div',{staticStyle:{"display":"inline"},attrs:{"id":"image"}},[_c('img',{staticClass:"waitlogo",staticStyle:{"width":"auto","height":"40px","vertical-align":"middle"},attrs:{"src":require("../assets/waiting-list-icon.png")}})]),_c('div',{staticStyle:{"display":"inline","vertical-align":"middle","font-size":"30px","color":"#31493c","font-family":"Calibri, sans-serif"}},[_vm._v("Waiting list")])]),_c('el-card',{staticClass:"box-card"},[_c('el-table',{attrs:{"data":_vm.data_list
                		.filter(
                			function (data) { return !_vm.input ||
                				data.Tissue.toLowerCase().includes(
                					_vm.input.toLowerCase()
                				) ||
                				data.Dataset.toLowerCase().includes(
                					_vm.input.toLowerCase()
                				); }
                		)
                		.slice(
                			(_vm.currentPage - 1) * _vm.pageSize,
                			_vm.currentPage * _vm.pageSize
                		),"stripe":"","border":"","default-sort":{
                	prop: 'Tissue',
                	order: 'ascending',
                },"row-class-name":_vm.tableRowClassName},on:{"sort-change":_vm.sortChange,"filter-change":_vm.filterChange,"row-click":_vm.onRowClick}},[_c('template',{slot:"empty"},[_c('div',{staticClass:"noData"},[_vm._v("No Results :(")])]),_c('el-table-column',{attrs:{"prop":"Tissue","label":"Tissue","align":"center","min-width":"15%","sortable":"custom","column-key":"Tissue"}}),_c('el-table-column',{attrs:{"prop":"GSE","label":"Source","align":"center","min-width":"15%","sortable":"custom"}}),_c('el-table-column',{attrs:{"prop":"Last_Update_Date","label":"Year","align":"center","min-width":"10%","sortable":"custom"}}),_c('el-table-column',{attrs:{"prop":"PMID","label":"PMID","align":"center","min-width":"10%","sortable":"custom"}}),_c('el-table-column',{attrs:{"prop":"State","label":"State","align":"center","min-width":"10%","sortable":"custom"}}),_c('el-table-column',{attrs:{"prop":"Platform","label":"Platform","align":"center","min-width":"15%","sortable":"custom"}})],2),_c('div',{staticClass:"block",staticStyle:{"text-align":"center"}},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-size":10,"layout":"prev, pager, next, jumper","total":_vm.data_list.filter(
                        		function (data) { return !_vm.input ||
                        			data.Tissue.includes(_vm.input); }
                        	).length},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }