<template>
    <div id="newdata">
        <el-row>
            <el-col>
                <div class="grid-content bg-purple-dark"></div>
                <div class="center">
                    <div id="image" style="display:inline;"><img class="waitlogo" src="../assets/waiting-list-icon.png"
                        style="width: auto; height: 40px;  vertical-align:middle" /></div>
                    <div style="display:inline; vertical-align:middle; font-size:30px; color: #31493c; font-family: Calibri, sans-serif">Waiting list</div>
                </div>

                <el-card class="box-card">
                    <el-table :data="
                    	data_list
                    		.filter(
                    			(data) =>
                    				!input ||
                    				data.Tissue.toLowerCase().includes(
                    					input.toLowerCase()
                    				) ||
                    				data.Dataset.toLowerCase().includes(
                    					input.toLowerCase()
                    				)
                    		)
                    		.slice(
                    			(currentPage - 1) * pageSize,
                    			currentPage * pageSize
                    		)
                    " stripe border :default-sort="{
                    	prop: 'Tissue',
                    	order: 'ascending',
                    }" @sort-change="sortChange" :row-class-name="tableRowClassName" @filter-change="filterChange"
                        @row-click="onRowClick">
                        <template slot="empty">
                            <div class="noData">No Results :(</div>
                        </template>

                        <el-table-column prop="Tissue" label="Tissue" align="center" min-width="15%" sortable="custom"
                            column-key="Tissue"></el-table-column>
                        <el-table-column prop="GSE" label="Source" align="center" min-width="15%" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="Last_Update_Date" label="Year" align="center" min-width="10%"
                            sortable="custom">
                        </el-table-column>
                        <el-table-column prop="PMID" label="PMID" align="center" min-width="10%" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="State" label="State" align="center" min-width="10%" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="Platform" label="Platform" align="center" min-width="15%"
                            sortable="custom"></el-table-column>

                    </el-table>

                    <div class="block" style="text-align: center">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="
                            	data_list.filter(
                            		(data) =>
                            			!input ||
                            			data.Tissue.includes(input)
                            	).length
                            "></el-pagination>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from "axios";
import data2022 from "@/assets/data2022.json"; // 引用
// import TissueFilter from "@/assets/alltissue1.json"; // 引用
// import allts from "../../public/alltissue1.json";
import { getHeatmap } from "@/api/getGene.ts";
// import SelectHeader from "@/components/SelectHeader";

export default {
    name: "info",

    data() {
        return {
            itemList: [],
            data_list: data2022,
            res: "",
            input: "",
            input_tissue: "",
            hover: false,
            DatasetName: "",
            currentPage: 1,
            pageSize: 10,
            isRouterAlive: true,
            // options: allts,
            tissuename: "",
            inputGene: "",
            select: "",
            show_gene_heatmap: false,
            // TissueFilter: TissueFilter,
        };
    },
    change(e) {
        this.$forceUpdate();
    },
    activated() {
        location.reload()
        this.$router.go(0)

    },
    created() {
    },
    computed: {},

    methods: {
        isShow() {
            this.show_gene_heatmap = false;
        },
        async getHeatmap() {
            try {
                this.$refs["mark"].focus();

                const response = await this.axios({
                    method: "post",
                    url: "get_heatmap/",
                    data: {
                        gene: this.inputGene,
                        tissuename: this.tissuename
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                this.img_heatmap = response["data"];
                this.show_gene_heatmap = true;
            } catch (err) {
                this.$alert(
                    "Please check the input genes, if you are not sure, you can browse our Datasets page to see related genes.",
                    "Gene",
                    {
                        confirmButtonText: "OK",
                        callback: action => {
                            this.$message({
                                type: "info",
                                message: `action: ${action}`
                            });
                        }
                    }
                );
            }
        },

        filterHandler(value, row, column) {
            const property = column["property"];
            return row[property] === value;
        },

        fnFilterChangeInit(filter) { },

        filterChange(filterObj) {
            this.input = JSON.stringify(filterObj.Tissue[0]).replace(
                /^"(.*)"$/,
                "$1"
            );
        },

        sortChange({ prop, order }) {
            this.data_list.sort(this.compare(prop, order));
        },

        compare(propertyName, sort) {
            return function (obj1, obj2) {
                var value1 = obj1[propertyName];
                var value2 = obj2[propertyName];
                if (typeof value1 === "string" && typeof value2 === "string") {
                    const res = value1.localeCompare(value2, "zh");
                    return sort === "ascending" ? res : -res;
                } else {
                    if (value1 <= value2) {
                        return sort === "ascending" ? -1 : 1;
                    } else if (value1 > value2) {
                        return sort === "ascending" ? 1 : -1;
                    }
                }
            };
        },

        tableRowClassName({ row, rowIndex }) {
            //Put the index of each row into row
            row.index = rowIndex;
        },

        handleSizeChange(val) {
            this.currentPage = 1;
            this.currentPage = val;
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },

    }
};
</script>


<style lang="scss" scoped >
@import "@/styles/info.scss";

.waitlogo {
    // height: 50px;
    //   padding: 10px;
    /* padding-right: 0px;
    padding-bottom: 10px;
    padding-top: 3px; */
}
.center {
    text-align: center;
}
#statistics {
    display: flex;
    flex-direction: row;
}

h2 {
    color: #567568;
}
</style>